import Vue from "vue";
import App from "./App.vue";
import Buefy from 'buefy'

const defaultConfig = {
  "title": "TSG Metadata Broker",  
  "logo": "/TSG-Logo-White.svg",
  "home": {
    "subtitle": "View the active registrations in this Metadata Broker",
    "paragraphs": [
      "The TSG Metadata Broker is a service for publishing and searching metadata of Connectors and resources between International Data Spaces Participants. In order to ensure the necessary interoperability and general interactions, an IDS Metadata Broker (like the App Store) is also defined as a specialized IDS Connector. The communication between an IDS Connector and an IDS Meta Data Broker is based on the same principles as any other Connector-Connector communication within the International Data Spaces. Still, an IDS Metadata Broker provides a collection of additional functionalities: ",
      "<ul><li>Indexing services in order to effectively and efficiently respond to queries and present known Connectors and other resources.</li><li>Interfaces for Users or IDS-Messages to ensure access to the stored information.</li></ul>"
    ]
  },
  "term": {
    "participant": "Participant",
    "participants": "Participants",
    "component": "Component",
    "components": "Components",
    "agent": "Agent",
    "agents": "Agents"
  },
  "emptyLogo": "/TSG-Logo-White.svg",
  "footer": {
    "logos": ["/TSG-Logo-White.svg"],
    "copyright": "© 2021 TNO - TSG"
  },
  "connectorView": ["tiles"],
  "participantView":["tiles"],
  "agentOverview": false,
  "participantOverview": true,
  "connectorLogos": true
}

fetch('config/config.json')
  .then(response => {
    return response.json();
  }).then((config) => {
    const mergedConfig = {...defaultConfig, ...config}
    console.log(mergedConfig);

    Vue.config.productionTip = false;

    Vue.use(Buefy);
    
    Vue.mixin({
      data() {
        return {
          config: mergedConfig
        }
      },
      methods: {
        download (filename, text) {
          var element = document.createElement("a");
          element.setAttribute(
            "href",
            `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`
          );
          element.setAttribute("download", filename);
        
          element.style.display = "none";
          document.body.appendChild(element);
        
          element.click();
        
          document.body.removeChild(element);
        }
      }
    });
    
    new Vue({
      render: h => h(App)
    }).$mount("#app");
});