<template>
  <div class="home">
    <div class="highlight">
      <div class="text is-size-4">
        <h1 class="title">{{ config.title }}</h1>
        <h2 class="subtitle">{{ config.home.subtitle }}</h2>
        <p class="has-text-grey content"
          v-for="(paragraph, idx) in config.home.paragraphs" 
          :key="`home-p-${idx}`"
          v-html="paragraph"        
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeComponent",
  props: {
    connectors: {
      type: Array,
      required: true,
    },
    participants: {
      type: Array,
      required: true,
    },
  },
  computed: {
    agents: function () {
      return this.connectors
        .map((c) => {
          return c.agents.map((a) => {
            return {
              title: a.title,
              id: a.id,
              versions: a.versions,
              serviceprovider: this.matchParticipant(c.maintainer),
            };
          });
        })
        .flat();
    },
  },
  data() {
    return {
      connectorFilter: "",
      agentsFilter: "",
    };
  },
  methods: {},
};
</script>

<style lang="scss">
.hero.is-bold {
  position: relative;
  z-index: 10;
  background-color: white;
}
.tabs.is-centered.is-large {
  position: relative;
  z-index: 10;
  background-color: white;
}
</style>

<style lang="scss" scoped>
@import "bulma/sass/utilities/_all.sass";

.highlight {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5rem;
  .text {
    .title {
      font-weight: bold;
    }
    .subtitle {
      color: var(--color-accent);
      font-weight: bold;
    }
    p {
      margin-bottom: 2rem;
    }
    width: 90%;
    padding: 1rem;
    position: relative;
    z-index: 12;
    @include from($desktop) {
      width: 70%;
    }
    @include from($widescreen) {
      width: 60%;
    }
    @include from($fullhd) {
      width: 50%;
    }
    p:nth-child(1) {
      padding-bottom: 1em;
    }
  }
}
.home {
  position: relative;
  min-height: 600px;
  .hero {
    position: relative;
    z-index: 9;
    width: 100%;
    .title {
      position: absolute;
      top: 15rem;
      z-index: 20;
      left: 10%;
      width: 80%;
      font-size: 4rem;
      @include from($tablet) {
        width: 80%;
        left: 10%;
        top: 20rem;
      }
      @include from($desktop) {
        width: 80%;
        left: 10%;
        top: 20rem;
      }
      @include from($widescreen) {
        width: 70%;
        left: 20%;
      }
      @include from($fullhd) {
        width: 70%;
        left: 15%;
        font-size: 5rem;
        top: 20rem;
      }
      @include from($fullhd*1.25) {
        font-size: 6rem;
        top: 25rem;
      }
    }
  }
}
</style>
