<template>
  <section class="section">
    <div>
      <div class="level">
        <div class="level-left">
          <h2 class="title is-4">{{ config.term.participants }}</h2>
        </div>
        <div class="field level-right">
          <div class="control">
            <input class="input" type="text" v-model="participantFilter" :placeholder="`Filter ${config.term.participants}`">
          </div>
        </div>
      </div>
      <b-table
        :data="filterParticipants(participants, participantFilter)"
        :paginated="true"
        :per-page="25"
        :selected.sync="selectedRow"
        focusable
        class="is-fullwidth"
        v-if="config.participantView.includes('table')"
        >

        <b-table-column field="idsid" label="IDS ID" v-slot="props">
          {{ props.row.titles[0].value }}
        </b-table-column>
        <b-table-column field="contact" label="Contact" v-slot="props">
          {{ props.row.contact }}
        </b-table-column>
        <b-table-column field="titles" label="Title" v-slot="props">          
          {{ props.row.idsid }}
        </b-table-column>
      </b-table>
      <template v-if="selectedParticipant">
        <h2 class="title is-4">Selected {{ config.term.participant }} {{ selectedParticipant['@id'] }}</h2>
        <JSONView
              style="overflow-x: auto"
              :data="selectedParticipant"
              rootKey="Participant"
              :maxDepth="1"
            />
      </template>


      <div class="card-columns" v-if="config.participantView.includes('tiles')">
        <div class="card-column" v-for="participant in participants" v-bind:key="participant.idsid">
          <div class="card">
            <div class="card-image">
              <figure class="image is-2by1">
                <div class="has-ratio">
                  <div>
                    <img v-if="participant.logo" :src="participant.logo" />
                    <img v-else :src="config.emptyLogo" />
                  </div>
                </div>
              </figure>
            </div>
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-4">{{ participant.titles[0].value }}</p>
                  <p class="subtitle is-6">{{ participant.idsid }}</p>
                </div>
              </div>
              <div class="content">
                <div class="tags has-addons">
                  <span class="tag is-dark">contact</span>
                  <span class="tag is-light">{{ participant.contact }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
$scsn-green: #72a7d1;
.table-wrapper {
    padding-bottom: 0.5rem;
}
.table tr.is-selected {
  background-color: $scsn-green;
}
</style>

<script>
import { JSONView } from 'vue-json-component';
export default {
  name: "ParticipantsComponent",
  components: {
    JSONView
  },
  props: {
    connectors: {
      type: Array,
      required: true
    },
    participants: {
      type: Array,
      required: true
    },
    participantsFull: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      participantFilter: "",
      selectedRow: undefined
    };
  },
  computed: {
    selectedParticipant() {
      if (this.selectedRow) {
        const participant = this.participantsFull.filter(element => element['@id'] === this.selectedRow['idsid'])[0]
        return Object.keys(participant).sort().reduce(
          (obj, key) => { 
            obj[key] = participant[key]; 
            return obj;
          }, {})
      }
      return undefined
    }
  },
  methods: {
    getState: function (data, inputValue, keys = [], state = false) {
      for (const value of (keys.length) ? keys.map(key => data[key]) : Object.values(data)) {
        if (typeof value === 'object' && value !== null && Object.keys(value).length > 0 && state === false) {
          state = this.getState(value, inputValue, [], state);
        } else {
          if (state === false) {
            state = JSON.stringify(value).toLowerCase().includes(inputValue.toLowerCase());
          } else {
            return state;
          }
        }
      }
      return state;
    },
    filterParticipants: function () {
        return this.participants.filter((element) => this.getState(element, this.participantFilter, ["idsid", "titles", "descriptions", "contact"]));
    },
  }
}
</script>