<template>
  <section class="section">
    <div class="level">
      <div class="level-left">
        <h2 class="title is-4">{{ config.term.agents}}</h2>
      </div>
      <div class="field level-right">
        <div class="control">
          <input
            class="input is-primary"
            type="text"
            v-model="agentsFilter"
            :placeholder="`Filter ${config.term.agents}`"
          />
        </div>
      </div>
    </div>
    <div>
      <b-table
        :data="agents"
        :paginated="true"
        :per-page="20"
        pagination-order="is-centered"
        class="is-fullwidth"
        :current-page.sync="currentPage"
        >
        <template #pagination>
            <b-pagination
                v-model="currentPage"
                :per-page="20"
                order="is-centered"
                :total="agents.length"
           />
       </template>
        <b-table-column field="title" width="40%" :label="config.term.agent" sortable v-slot="props">
          {{ props.row.title }}
        </b-table-column>
        <b-table-column field="id" width="10%" label="SCSN ID" sortable v-slot="props">
          <span class="tag is-link is-family-monospace">{{props.row.id}}</span>
        </b-table-column>
        <b-table-column field="versions" width="20%" label="SCSN API Versions" :custom-sort="compareVersions" sortable v-slot="props">
              <div class="tags">
                <span
                  class="tag is-success"
                  v-for="(version, vidx) in props.row.versions"
                  v-bind:key="props.row.id+'-'+vidx"
                >{{version}}</span>
              </div>
        </b-table-column>
        <b-table-column field="serviceprovider" width="30%" :label="config.term.participant" sortable v-slot="props">
          {{ props.row.serviceprovider }}
        </b-table-column>
      </b-table>
    </div>
  </section>
</template>

<script>
export default {
  name: "AgentsComponent",
  props: {
    connectors: {
      type: Array,
      required: true,
    },
    participants: {
      type: Array,
      required: true,
    },
  },
  computed: {
    agents: function () {
      return this.connectors
        .map((c) => {
          return c.agents.map((a) => {
            return {
              title: a.title,
              id: a.id,
              versions: a.versions,
              serviceprovider: this.matchParticipant(c.curator),
            };
          });
        })
        .flat()
        .filter((element) =>
          this.getState(element, this.agentsFilter, ["id", "title", "serviceprovider"])
        );
    },
    numpages: function () {
      return Math.ceil(this.agents.length / this.pagesize);
    },
  },
  data() {
    return {
      pagesize: 25,
      page: 1,
      agentsFilter: "",
      currentPage: 1
    };
  },
  methods: {
    getState: function (data, inputValue, keys = [], state = false) {
      for (const value of keys.length
        ? keys.map((key) => data[key])
        : Object.values(data)) {
        if (value === undefined) {
          return false
        }
        if (
          typeof value === "object" &&
          value !== null &&
          Object.keys(value).length > 0 &&
          state === false
        ) {
          state = this.getState(value, inputValue, [], state);
        } else {
          if (state === false) {
            state = JSON.stringify(value)
              .toLowerCase()
              .includes(inputValue.toLowerCase());
          } else {
            return state;
          }
        }
      }
      return state;
    },
    filterConnectors: function () {
      return this.connectors.filter((element) =>
        this.getState(element, this.connectorFilter, [
          "idsid",
          "titles",
          "descriptions",
          "curator",
          "maintainer",
        ])
      );
    },
    filterAgents: function (data) {
      return data.filter((element) =>
        this.getState(element, this.agentsFilter)
      );
    },
    matchParticipant: function (idsId) {
      const filteredParticipants = this.participants.filter(
        (p) => p.idsid === idsId
      );
      if (filteredParticipants.length > 0) {
        return filteredParticipants[0].titles[0].value;
      } else {
        return idsId;
      }
    },
    matchParticipantLogo: function (idsId) {
      const filteredParticipants = this.participants.filter(
        (p) => p.idsid === idsId
      );
      if (filteredParticipants.length > 0) {
        return filteredParticipants[0].logo;
      } else {
        return undefined;
      }
    },
    compareVersions: function (a, b, isAsc) {
      const aTest = Math.max.apply(null, a.versions.map(version => parseInt(version.replaceAll(".",""))))
      const bTest = Math.max.apply(null, b.versions.map(version => parseInt(version.replaceAll(".",""))))
      return (aTest - bTest) * (isAsc ? 1 : -1);
    }
  },
};
</script>


<style lang="scss" scoped>
.table {
  th:nth-child(1) {
    width: 35%;
  }
  th:nth-child(2) {
    width: 20%;
  }
  th:nth-child(3) {
    width: 15%;
  }
  th:nth-child(4) {
    width: 30%;
  }
}
@media screen and (max-width: 1024px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }
  .table {
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    tr {
      border-bottom: 1px solid #ccc;
      padding-bottom: 0.5em;
      margin-bottom: 0.5em;
      td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        margin-left: 175px;
        &:last-child {
          border-bottom: none;
        }
        &:before {
          position: absolute;
          top: 9px;
          left: 6px;
          width: 200px;
          padding-right: 40px;
          white-space: nowrap;
          margin-left: -175px;
          font-weight: bold;
        }
        &:nth-of-type(1):before {
          content: "Company";
        }
        &:nth-of-type(2):before {
          content: "SCSN ID";
        }
        &:nth-of-type(3):before {
          content: "SCSN API Versions";
        }
        &:nth-of-type(4):before {
          content: "Service Provider";
        }
      }
    }
  }
}
</style>
