<template>
  <section class="section">
    <div class="level">
      <div class="level-left">
        <h2 class="title is-4">{{ config.term.components }}</h2>
      </div>
      <div class="field level-right">
        <div class="control">
          <input
            class="input"
            type="text"
            v-model="connectorFilter"
            :placeholder="`Filter ${config.term.components}`"
          />
        </div>
      </div>
    </div>
    <b-table
      v-if="config.connectorView.includes('table')"
      :data="filterConnectors(connectors, connectorFilter)"
      :paginated="true"
      :per-page="25"
      :selected.sync="selectedRow"
      focusable
      >      
      <b-table-column field="logo" label="Logo" v-slot="props" v-if="config.connectorLogos">
        <figure class="image is-96x96">
          <img
            v-if="matchParticipantLogo(props.row.curator)"
            :src="matchParticipantLogo(props.row.curator)"
          />
          <img
            v-else
            :src="config.emptyLogo"
          />
        </figure>
      </b-table-column>
      <b-table-column field="idsid" label="IDS ID" v-slot="props">
        {{ props.row.idsid }}
      </b-table-column>
      <b-table-column field="curator" label="Curator (Maintainer)" v-slot="props">
        {{ props.row.curator }}<br />(<small>{{ props.row.maintainer}}</small>)
      </b-table-column>
      <b-table-column field="titles" label="Title" v-slot="props">
        {{ props.row.titles[0].value }}
      </b-table-column>
      <b-table-column field="descriptions" label="Description" v-slot="props">
        {{ props.row.descriptions[0].value }}
      </b-table-column>
    </b-table>
    <template v-if="selectedConnector">
      <h2 class="title is-4">Selected {{ config.term.component }} {{ selectedConnector['@id'] }}</h2>
      <JSONView
        style="overflow-x: auto"
        :data="selectedConnector"
        rootKey="Connector"
        :maxDepth="1"
      />
    </template>
    <div class="is-clearfix"></div>
    <div class="card-columns" v-if="config.connectorView.includes('tiles')">
      <div
        class="card-column"
        v-for="connector in filterConnectors(connectors, connectorFilter)"
        :key="connector.key"
      >
        <div class="card">
          <div class="card-content">
            <div class="media">
              <div class="media-left">
                <figure class="image is-96x96">
                  <img
                    v-if="matchParticipantLogo(connector.curator)"
                    :src="matchParticipantLogo(connector.curator)"
                  />
                  <img
                    v-else
                    :src="config.emptyLogo"
                  />
                </figure>
              </div>
              <div class="media-content">
                <p class="title is-5">{{ connector.titles[0].value }}</p>
                <p class="subtitle is-6 is-italic">{{ matchParticipant(connector.curator) }}</p>
              </div>
            </div>
            <div>
              <template v-for="(description, idx) in connector.descriptions">
                <span
                  v-bind:key="idx"
                  class="is-italic pr-3"
                  v-if="['en', 'nl'].includes(description.lang)"
                >{{ description.value }}</span>
              </template>
              <div
                class="field is-grouped is-grouped-multiline pt-3"
                style="justify-content: center;"
              >
                <div class="control">
                  <div class="tags is-medium has-addons">
                    <span class="tag is-dark">Agents</span>
                    <span class="tag is-accent">{{ connector.agents.length }}</span>
                  </div> 
                </div>
                <div class="control" v-if="connector.agents.length > 0">
                  <div class="tags is-medium has-addons">
                    <span class="tag is-dark">Versions</span>
                    <span class="tag is-accent">
                      <span
                        v-bind:key="idx"
                        v-for="(version, idx) in new Set(connector.agents.map(a => a.versions).flat())"
                      >
                        <span v-if="idx != 0" class="px-1">|</span>
                        {{ version }}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer class="card-footer">
            <a
              href="#"
              class="card-footer-item"
              @click="showConnectorModal(connector)"
            >
              More
              info
            </a>
            <div class="modal is-clipped">
              <div
                class="modal-background"
                v-on:click="$event.target.parentElement.classList.remove('is-active')"
              >
              </div>
              <div class="modal-content">
                <div class="panel">
                  <p class="panel-heading">
                    {{ connector.titles[0].value }}
                    <button
                      class="delete is-pulled-right"
                      aria-label="close"
                      v-on:click="$event.target.parentElement.parentElement.parentElement.parentElement.classList.remove('is-active')"
                    ></button>
                  </p>
                  <template v-if="connector.titles.length > 1">
                    <div
                      v-bind:key="idx"
                      class="panel-block has-background-white"
                      v-for="(title, idx) in connector.titles.slice(1)"
                    >
                      <p>{{ title.value }}</p>
                    </div>
                  </template>
                  <div class="panel-block is-block has-background-white">
                    <h4 class="title mb-0 is-5">Descriptions</h4>
                    <p
                      v-bind:key="idx"
                      v-for="(description, idx) in connector.descriptions"
                    >{{ description.value }}
                    </p>
                  </div>
                  <div class="panel-block is-block has-background-white">
                    <h4 class="title mb-0 is-5">
                      Curator
                      <sup
                        class="has-tooltip-info has-tooltip-multiline has-tooltip-right"
                        data-tooltip="The curator of a connector is responsible for the content of the connector"
                      >
                        <span class="icon has-text-info is-small">
                          <i class="fas fa-info-circle"></i>
                        </span>
                      </sup>
                    </h4>
                    <p>
                      <span
                        class="tag is-family-monospace is-link is-pulled-right pl-2"
                      >{{connector.curator}}</span>
                      <span>{{ matchParticipant(connector.curator) }}</span>
                    </p>
                  </div>
                  <div class="panel-block is-block has-background-white">
                    <h4 class="title mb-0 is-5">
                      Maintainer
                      <sup
                        class="has-tooltip-info has-tooltip-multiline has-tooltip-right"
                        data-tooltip="The maintainer of a connector is responsible for the technical operation of the connector"
                      >
                        <span class="icon has-text-info is-small">
                          <i class="fas fa-info-circle"></i>
                        </span>
                      </sup>
                    </h4>
                    <p>
                      <span
                        class="tag is-family-monospace is-link is-pulled-right pl-2"
                      >{{connector.maintainer}}</span>
                      <span>{{ matchParticipant(connector.maintainer) }}</span>
                    </p>
                  </div>
                  <div class="panel-block has-background-white">
                    <p class="control has-icons-left">
                      <input
                        class="input"
                        type="text"
                        v-model="agentsFilter"
                        placeholder="Search agents"
                      />
                      <span class="icon is-left">
                        <i class="fas fa-search" aria-hidden="true"></i>
                      </span>
                    </p>
                  </div>
                  <div
                    v-bind:key="idx"
                    class="panel-block is-block has-background-white"
                    v-for="(agent, idx) in filterAgents(connector.agents).slice(0,5)"
                  >
                    <span
                      class="tag is-link is-family-monospace is-pulled-right pl-2"
                    >{{ agent.id }}</span>
                    <span>{{ agent.title }}</span>
                  </div>
                </div>
              </div>
            </div>
          </footer>          
        </div>
      </div>
      <div
        v-if="filterConnectors(connectors, connectorFilter).length < 3"
        class="card-column"
        style="height: 400px"
      >&nbsp;
      </div>
      <div
        v-if="filterConnectors(connectors, connectorFilter).length < 2"
        class="card-column"
        style="height: 400px"
      >
        &nbsp;
      </div>
    </div>
    <b-modal :active.sync="connectorModal.isActive" has-modal-card v-if="connectorModal.connector">
      <div class="modal-card" style="">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ connectorModal.connector.titles[0].value }}</p>
        </header>
        <section class="modal-card-body">
          <b-field label="Descriptions">
            <p
              class="control"
              v-bind:key="idx"
              v-for="(description, idx) in connectorModal.connector.descriptions">
                {{ description.value }}
            </p>            
          </b-field>
          <b-field label="Curator">
            <p>
              {{ matchParticipant(connectorModal.connector.curator) }}
            </p>            
          </b-field>
          <b-field label="Maintainer">
            <p>
              {{ matchParticipant(connectorModal.connector.maintainer) }}
            </p>            
          </b-field>
          <b-field label="Available Resources" />
          <b-table
            :data="connectorModal.connector.agents"
            :per-page="5"
            focusable
            class="has-text-weight-normal"
            >      
            <b-table-column field="resourceName" label="Resource name" v-slot="props" class="is-size-1">
              {{ props.row.title }}<br /><code>{{ props.row.id }}</code>
            </b-table-column>
            <!--
            <b-table-column field="versions" label="Versions" v-slot="props">            
              {{ props.row.versions.map(x => x.version) }}
            </b-table-column>
            <b-table-column field="apiDocs" label="Documentations" v-slot="props">
              {{ props.row.versions.map(x => x.documentation).flat() }}
            </b-table-column>
            -->
            <b-table-column field="apiDocs" label="Versions" v-slot="props">
              <b-table
                :data="props.row.versions"
                :per-page="5"
                focusable
              >
                <b-table-column field="apiDocs" label="Version" v-slot="props">
                  {{ props.row.version }}
                </b-table-column>
                <b-table-column field="apiDocs" label="Documentation" v-slot="props">
                  {{ props.row.documentation[0] }}
                </b-table-column>
              </b-table>              

            </b-table-column>
            <section slot="empty" class="section">
              <div class="content has-text-grey has-text-centered">
                <p>No resources Available</p>
              </div>
            </section>
          </b-table>          
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="connectorModal.isActive = false">Close</button>
        </footer>
      </div>
    </b-modal>
  </section>  
</template>

<style lang="scss">
.table-wrapper {
    padding-bottom: 0.5rem;
}
</style>

<script>
import { JSONView } from 'vue-json-component';

export default {
  name: "ConnectorsComponents",
  components: {
    JSONView
  },
  props: {
    connectors: {
      type: Array,
      required: true
    },
    connectorsFull: {
      type: Array,
      required: true
    },
    participants: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      connectorFilter: "",
      selectedRow: undefined,
      agentsFilter: "",
      connectorModal: {
        isActive: false,
        connector: undefined
      }
    }
  },
  computed: {
    selectedConnector() {
      if (this.selectedRow) {
        const connector = this.connectorsFull.filter(element => element['@id'] === this.selectedRow['idsid'])[0]
        return Object.keys(connector).sort().reduce(
          (obj, key) => { 
            obj[key] = connector[key]; 
            return obj;
          }, {})
      }
      return undefined
    }
  },
  methods: {
    async showConnectorModal (connector) {
      this.connectorModal = {
        isActive: true,
        connector: connector
      }      
    },
    getState: function (data, inputValue, keys = [], state = false) {
      for (const value of (keys.length) ? keys.map(key => data[key]) : Object.values(data)) {
        if (typeof value === 'object' && value !== null && Object.keys(value).length > 0 && state === false) {
          state = this.getState(value, inputValue, [], state);
        } else {
          if (state === false) {
            state = JSON.stringify(value).toLowerCase().includes(inputValue.toLowerCase());
          } else {
            return state;
          }
        }
      }
      return state;
    },
    filterConnectors: function () {
        return this.connectors.filter((element) => this.getState(element, this.connectorFilter, ["idsid", "titles", "descriptions", "curator", "maintainer"]));
    },
    selectConnector: function (idsid) {
      this.selectedConnector = this.connectorsFull.filter(element => element['@id'] === idsid)
    },
    matchParticipant: function (idsId) {        
        const filteredParticipants = this.participants.filter(p => p.idsid === idsId)
        if (filteredParticipants.length > 0) {
            return filteredParticipants[0].titles[0].value
        } else {
            return idsId
        }
    },
    filterAgents: function (data) {
      return data.filter((element) => this.getState(element, this.agentsFilter));
    },
    matchParticipantLogo: function (idsId) {
        const filteredParticipants = this.participants.filter(p => p.idsid === idsId)
        if (filteredParticipants.length > 0) {
            return filteredParticipants[0].logo;
        } else {
            return undefined;
        }
    }
  }
}
</script>